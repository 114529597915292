import React, { useRef } from 'react';

const Teams = () => {
  return (
    <div>
      <h1>Teams</h1>
      <p>Here's some information about our teams.</p>
    </div>
  );
}

export default Teams;
